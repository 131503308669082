const ROUTES = {
  initial: '/',
  logOut: '/logout',
  defaultRedirect: '/disposals',

  organisation: {
    disposals: {
      root: '/disposals',
      manage: {
        summary: '/summary',
        interest_schedule: '/interest-schedule',
        insights: '/insights',
      },
    },
  },
  marketplace: {
    marketplace_members: '/marketplace-members',
    marketplace_events: '/marketplace-events',
  },
};

export default ROUTES;
